<template>
    <v-main>
        <v-container fluid>
            <v-col cols="12">
                <v-row align="center" justify="center" style="height: 600px;">
                    <v-flex xs12 sm8 md4 style="min-width: 400px">
                        <form @submit.prevent="login">
                            <v-card class="elevation-12">
                                <v-card-text>
                                    <v-text-field
                                        type="email"
                                        v-model="email"
                                        label="E-mail"
                                        prepend-icon="mdi-account"
                                    ></v-text-field>
                                    <v-text-field
                                        type="password"
                                        v-model="password"
                                        label="Password"
                                        prepend-icon="mdi-lock"
                                    ></v-text-field>
                                    <v-btn block type="submit" text color="black" :loading="loading" @click="login">
                                        <v-icon left>mdi-login</v-icon>Login
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </form>
                    </v-flex>
                </v-row>
            </v-col>
        </v-container>

        <v-snackbar v-model="snackbar" style="position: fixed; bottom: 48px; width: 100%; z-index: 999;" color="secondary" light>
            <span>{{ errorMessage }}</span>
            <v-btn class="ml-2" color="black" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
    </v-main>
</template>

<script>

export default {
    data() {
        return {
            email: "",
            password: "",
            errorMessage: "",
            loading: false,
            snackbar: false
        };
    },
    methods: {
        login() {
            const data = { email: this.email, password: this.password }

            this.loading = true
            this.api.post('/login/', data)
                .then(resp => {

                    const token = resp.data.token

                    this.$session.start()
                    this.$session.set('token', token)
                    this.$session.set('user_email', data.email)

                    this.$router.push('/')
                })
                .catch(resp => {
                    this.snackbar = true
                    this.errorMessage = resp.response.status == 401 ? 'El usuario o contraseña son incorrectos' : 'Se ha producido un error inesperado'
                })
                .finally(() => this.loading = false)
        }
    }
};
</script>
