var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"my-4"},[_vm._v("Usuarios")]),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.users,"headers":[
            { text: 'Id.', value: 'id' },
            { text: 'Nombre', value: 'privateInfo.name' },
            { text: 'Apellidos', value: 'privateInfo.firstlastname' },
            { text: 'Teléfono', value: 'privateInfo.phone_number' },
            { text: 'Correo', value: 'mail' },
            { text: 'Ciudad', value: 'location.city_user' },
            { text: 'Código postal', value: 'location.postal_code' },
            { text: 'Id Tag', value: 'id_tag' },
            { text: 'Activo', value: 'status', align: 'center' } ],"items-per-page":10,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.status == 'A',"ripple":false,"color":"primary"}})]}}])}),(false)?_c('v-row',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","color":"primary","link":"","to":"/users/create"}},[_vm._v(" Crear nuevo usuario ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }