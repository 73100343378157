<template>
  <v-app>
    <v-app-bar
      app
      color="secondary"
      class="app_bar"
      :height="80"
      dark
    >
      <div class="d-flex align-center">
        <a href="https://www.dip-badajoz.es/" target="_blank">
          <LogoWithName style="height: 70px; width: 224px;"/>
        </a>
      </div>

      <v-spacer />

      <v-btn
        v-if="isLoggedIn"
        @click="logout"
        color="black"
        text
      > 
        <span>{{ getUserEmail().toLowerCase() }}</span>
        <v-icon class="ml-2" color="black" small>mdi-logout-variant</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs
          class="mr-1"
          active-class="accent_color"
          right
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            key="transactions"
            link to="/transactions"
          >
            Transacciones
          </v-tab>
          <v-tab
            key="users"
            link to="/users"
          >
            Usuarios
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main class="mt-2">
      <router-view/>
    </v-main>

    <div style="position: fixed; bottom: 42px; width: 100%; z-index: 999;">
      <v-alert
          v-model="alert.show"
          dismissible
          transition="slide-y-reverse-transition"
          :type="alert.type"
          :icon="alert.icon"
          class="ma-3"
      >
          {{ alert.text }}
      </v-alert>
    </div>

    <v-footer
      class="footer"
      padless
    >
      <v-col
        class="text-center"
        cols="6"
      >
        <span>Desarrollado por el equipo de @<a style="color: black !important; text-decoration-color: black !important;" href="https://wenea.com/" target="_blank">Wenea</a> para Diputación de Badajoz.</span>
      </v-col>
      <v-col
        class="text-center"
        cols="6"
      >
        <span>Ver. {{ app_version }}</span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import LogoWithName from './components/icons/LogoWithName.vue';
import { EventBus } from './evnt-bus.js'

export default {
  name: 'App',
  components: {
    LogoWithName
  },
  data: () => ({
    alert: {
      show:false,
      type: null,
      icon: null,
      text: null
    }
  }),
  async created() {

        EventBus.$on("alert", event => {
          this.alert = { ...this.alert, show: true, type: event.type, text: event.text}
          
          setTimeout(() => this.alert = { ...this.alert, show: false}, 4000)
        })

    },
  computed: {
    isLoggedIn() {
      this.$route // The route is checked so that the value is updated every time the route changes
      return !!this.$session.get('token')
    }
  },
  methods: {
    logout() {
      this.$session.destroy()
      location.reload()
    },
    getUserEmail(){
      return this.$session.get('user_email')
    }
  }
};
</script>

<style scope>
  .v-toolbar__extension {
    height: 58px!important;
    background-color: var(--v-primary-base);
  }
  .accent_color {
    background-color: var(--v-accent-base);
  }
  .v-btn {
    text-transform: none !important;
  }
  .footer {
    font-size: 12px;
  }
</style>