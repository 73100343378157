<template>
    <v-form class="mt-8">
        <v-row>
            <v-col
            cols="12"
            md="6"
            >
                <v-text-field
                    v-model="user.email"
                    label="Correo"
                    required
                ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            md="6"
            >
                <v-text-field
                    v-model="user.password"
                    label="Contraseña"
                    type="password"
                    required
                ></v-text-field>
            </v-col>

            <v-col
            cols="12"
            md="6"
            >
                <v-text-field
                    v-model="user.name"
                    label="Nombre"
                    required
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="6"
            >
                <v-text-field
                    v-model="user.lastname"
                    label="Apellidos"
                    required
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="6"
            >
                <v-text-field
                    v-model="user.phone"
                    label="Teléfono"
                    required
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="6"
            >
                <v-text-field
                    v-model="user.dni"
                    label="DNI"
                    required
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="4"
            >
                <v-text-field
                    v-model="user.city"
                    label="Ciudad"
                    required
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="4"
            >
                <v-text-field
                    v-model="user.postal_code"
                    label="Código postal"
                    required
                ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="4"
            >
                <v-text-field
                    v-model="user.address"
                    label="Dirección"
                    required
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-btn
                class="mr-2"
                width="100"
                color="primary"
                depressed
                :loading="submit_loading"
                @click="submit"
                >
            {{ submit_label }}
            </v-btn>

            <v-btn
                class="cancel-btn"
                width="100"
                depressed
                @click="$router.go(-1)"
                >
                Cancelar
            </v-btn>
        </v-row>
  </v-form>
</template>

<script>

export default {
    props: {
        submit_label: {
            type: String,
            default: 'Enviar'
        },
        submit_loading: {
            type: Boolean,
            default: false
        },
        email: {
            type: String,
            default: null
        },
        password: {
            type: String,
            default: null
        },
        active: {
            type: Boolean,
            default: true
        },
        group: {
            type: String,
            default: 'Diputación'
        },
        name: {
            type: String,
            default: null
        },
        lastname: {
            type: String,
            default: null
        },
        phone: {
            type: String,
            default: null
        },
        dni: {
            type: String,
            default: null
        },
        city: {
            type: String,
            default: null
        },
        postal_code: {
            type: String,
            default: null
        },
        address: {
            type: String,
            default: null
        },
    },
    data(){
        return {
            user: {
                email: this.email,
                password: this.password,
                active: this.active,
                group: this.group,
                name: this.name,
                lastname: this.lastname,
                phone: this.phone,
                dni: this.dni,
                city: this.city,
                postal_code: this.postal_code,
                address: this.address,
            }
        }
    },
    methods: {
        submit(){
            this.$emit('submit', this.user)
        }
    }
};
</script>

<style scope>
    .cancel-btn {
        color: black !important;
        border: 1px solid #ccc!important;
    }
</style>