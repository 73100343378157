<template>
    <v-container>
        <h2 class="my-4">Usuarios</h2>
        <v-divider />
        <v-data-table
            :items="users"
            :headers="[
                { text: 'Id.', value: 'id' },
                { text: 'Nombre', value: 'privateInfo.name' },
                { text: 'Apellidos', value: 'privateInfo.firstlastname' },
                { text: 'Teléfono', value: 'privateInfo.phone_number' },
                { text: 'Correo', value: 'mail' },
                { text: 'Ciudad', value: 'location.city_user' },
                { text: 'Código postal', value: 'location.postal_code' },
                { text: 'Id Tag', value: 'id_tag' },
                { text: 'Activo', value: 'status', align: 'center' },
            ]"
            :items-per-page="10"
            :loading="loading"
            dense
        >
            <template v-slot:item.status="{ item }">
                <v-simple-checkbox
                    :value="item.status == 'A'"
                    :ripple="false"
                    color="primary"
                />
            </template>
        </v-data-table>

        <v-row class="mt-4" v-if="false">
            <v-spacer />
            <v-btn
                depressed
                color="primary"
                link to="/users/create">
                Crear nuevo usuario
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            users: [],
            loading: false
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){

            this.loading = true
            this.api.get('/users/')
                .then(resp => {
                    this.users = resp.data.users
                })
                .catch(this.showError)
                .finally(() => this.loading = false)
        }
    }
};
</script>