<template>
    <v-container>
        <h2 class="my-4">Crear usuario</h2>
        <UserForm 
            submit_label="Crear"
            :submit_loading="loading"
            @submit="createUser"
        />
    </v-container>
</template>

<script>
import UserForm from './User.form.vue'

export default {
    components:{
        UserForm
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        createUser(user){
            const data = user

            this.loading = true
            this.api.post('/user/', data)
                .then(() => {
                    this.showSuccess('Usuario creado correctamente.')
                    this.$router.push('/users/')
                })
                .catch(this.showError)
                .finally(() => this.loading = false)
        }
    }
};
</script>