
import axios from 'axios'
import Vue from 'vue'

const api = axios.create({
    baseURL: `/api/v7/external/deputy`
})

api.interceptors.request.use(function (config) {
    if (Vue.prototype.$session.has('token')){
        config.headers.Authorization = `Token ${Vue.prototype.$session.get('token')}`;
    }
    return config;
});

/**
 * Si alguna de las respuestas HTTP a la API devuelve error 401 (Unauthenticatd), hacemos logout 
 * y redirijimos a la pantalla de login
 */
 api.interceptors.response.use(
    response => { return response },
    error => {
        if (error.response.status === 401 && Vue.prototype.$session.exists()) { Vue.prototype.$session.destroy(); window.location.reload() }
        return Promise.reject(error)
    }
)

export default api