import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { es },
      current: 'es',
    },
    theme: {
      options: { customProperties: true },
      themes: {
        light: {
          primary: '#cc0000',
          secondary: '#f8f9fa',
          accent: '#980404',
          error: '#ff4444',
          success: '#00c851',
        },
      },
    },
  });