var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"my-4"},[_vm._v("Transacciones")]),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.transactions,"headers":[
            { text: 'UUID', value: 'uuid' },
            { text: 'Punto de carga', value: 'recharge_point' },
            { text: 'Conector', value: 'plug_name' },
            { text: 'Id. Tag', value: 'tag_id' },
            { text: 'Carga parcial', value: 'full_charge', align: 'center' },
            { text: 'Tiempo de carga', value: 'charge_time' },
            { text: 'Batería', value: 'battery_pct' },
            { text: 'Energia', value: 'energy' },
            { text: 'Potencia', value: 'power' },
            { text: 'Inicio', value: 'started_at' },
            { text: 'Fin', value: 'stopped_at' }
        ],"items-per-page":_vm.tableOptions.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength,"footer-props":{ 'items-per-page-options': _vm.pagination.itemsPerPageOptions },"loading":_vm.loading,"dense":""},on:{"update:options":function (args) { _vm.tableOptions = args; _vm.load()}},scopedSlots:_vm._u([{key:"item.full_charge",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":!item.full_charge,"ripple":false,"color":"primary"}})]}},{key:"item.battery_pct",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(item.battery_pct)+" %")]}},{key:"item.energy",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s((item.energy / 1000).toFixed(2))+" kWh")]}},{key:"item.power",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s((item.power / 1000).toFixed(2))+" kW")]}},{key:"item.started_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateFormat")(item.started_at)))]}},{key:"item.stopped_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateFormat")(item.stopped_at)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }