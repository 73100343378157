import Vue      from 'vue'
import Router   from 'vue-router'


import Login        from './views/Login.vue'
import Transactions from './views/Transactions.vue'
import Users        from './views/Users.vue'
import UserCreate   from './views/User.create.vue'

Vue.use(Router)

const router = new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/transactions'
        },
        {
            path: '/transactions',
            name: 'transactions',
            component: Transactions
        },
        {
            path: '/users',
            name: 'users',
            component: Users
        },
        {
            path: '/users/create',
            name: 'usercreate',
            component: UserCreate
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: { guestAllow: true }
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.guestAllow)) {
        next()
    } else {
        if (router.app.$session.get('token')) {
            next()
            return
        }
        next('/login')
    }
})

export default router