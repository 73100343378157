import api from './backoffice.api.js'
import { EventBus } from '../evnt-bus.js'
import { parseISO, format } from "date-fns"

export default {
    data: function () {
        return {
            api: api,
            app_version: process.env.PACKAGE_VERSION
        }
    },
    filters: {
        dateFormat(isoFormat){
            if( !isoFormat ){ return '' }
            return format(parseISO(isoFormat), "yyyy-MM-dd HH:mm:ss")
        },
    },
    methods: {
        showError(error){

            let text = ''

            switch(typeof error){
                case 'object':

                    if( error.response && error.response.data && error.response.data.error ){
                        text = error.response.data.error.message
                        console.error(text, error.response.data.error)
                    } else {
                        text = error.message
                        if( error.response && typeof error.response.data === 'string'){
                            text += '. '+error.response.data
                        }
                        if( error.response && typeof error.response.data === 'object'){
                            text += '. '+JSON.stringify(error.response.data)
                        }
                    }

                break;
                default:
                    
                    text = error

                break;
            }

            EventBus.$emit('alert', {text, type: 'error'})
        },
        showSuccess(text){
            EventBus.$emit('alert', {text, type: 'success'})
        }
    }
}