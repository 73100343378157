<template>
    <v-container>
        <h2 class="my-4">Transacciones</h2>
        <v-divider />
        <v-data-table
            :items="transactions"
            :headers="[
                { text: 'UUID', value: 'uuid' },
                { text: 'Punto de carga', value: 'recharge_point' },
                { text: 'Conector', value: 'plug_name' },
                { text: 'Id. Tag', value: 'tag_id' },
                { text: 'Carga parcial', value: 'full_charge', align: 'center' },
                { text: 'Tiempo de carga', value: 'charge_time' },
                { text: 'Batería', value: 'battery_pct' },
                { text: 'Energia', value: 'energy' },
                { text: 'Potencia', value: 'power' },
                { text: 'Inicio', value: 'started_at' },
                { text: 'Fin', value: 'stopped_at' }
            ]"
            :items-per-page="tableOptions.itemsPerPage"
            :server-items-length="pagination.serverItemsLength"
            :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
            @update:options="(args) => { tableOptions = args; load()}"
            :loading="loading"
            dense
        >
            <template v-slot:item.full_charge="{ item }">
                <v-simple-checkbox
                    :value="!item.full_charge"
                    :ripple="false"
                    color="primary"
                />
            </template>
            <template v-slot:item.battery_pct="{ item }">{{ item.battery_pct }} %</template>
            <template v-slot:item.energy="{ item }">{{ (item.energy / 1000).toFixed(2) }} kWh</template>
            <template v-slot:item.power="{ item }">{{ (item.power / 1000).toFixed(2) }} kW</template>
            <template v-slot:item.started_at="{ item }">{{ item.started_at | dateFormat }}</template>
            <template v-slot:item.stopped_at="{ item }">{{ item.stopped_at | dateFormat }}</template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    data(){
        return {
            transactions: [],
            pagination: {
                itemsPerPageOptions: [10, 25, 50, 100],
                serverItemsLength: 0
            },
            tableOptions: {
                itemsPerPage: 25,
                page: 1,
            },
            loading: false
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            this.loading = true

            let queryParams = ''

            queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
            if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }

            this.api.get(`/transactions?${queryParams}`)
                .then(resp => {
                    this.pagination.serverItemsLength = resp.data.count
                    this.transactions = resp.data.results
                })
                .catch(this.showError)
                .finally(() => this.loading = false)
        }
    }
};
</script>